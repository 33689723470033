import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesLJT: Record<string, any> = {
    'TITLE': 'Les Jardins de Toulhars',
    'SOUSTITLE': '',
    'STATUT': 'LANCEMENT COMMERCIAL',
    'LOGO': '../Programs/LJT/1.jpg',
    'LOCALISATION': 'Larmor-Plage',
    'URL': '/nos-programmes/ljt',
    'ADDRESS': 'Rue des architectes',
    'ADDRESSCOUNTRY': '56260 LARMOR-PLAGE',
    'LAT': 47.71059716707548,
    'LONG': -3.3790162319965615,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1':
        <p>
            Cette nouvelle résidence haut de gamme est composée de 8 logements très lumineux, ouverts sur de larges terrasses.
            <br />
            Située à 150 mètres de la belle plage de Toulhars, et donnant directement  sur le parc, cette résidence est logée au sein d'un écrin de verdure qui réunit bord de mer et nature.
        </p>,
    'IMGONGLET1': '../Programs/LJT/2.jpg',
    'ONGLET2': 'Les appartements',
    'TEXTONGLET2': 'Les prestations de grande qualité (parquet, carrelage grand format PORCELANOSA, baie vitrée coulissante en aluminium thermolaqué, double vitrage auto-nettoyant à faible émissivité, volet roulant motorisé, interphone, digicode, hall sécurisé, ascenseur et garage privatif en sous-sol…), et les normes de plus en plus rigoureuses, notamment en matière de confort thermique, d’isolation acoustique et d’économie d’énergie, confèrent à la résidence tous les atouts de la modernité.',
    'IMGONGLET2': '../Programs/LJT/1.jpg',
    'ONGLET3': 'Larmor-Plage (56)',
    'TEXTONGLET3': 'Au cœur de la station balnéaire de Larmor-Plage, à quelques pas de l’océan, la résidence « Les Terrasses de Toulhars » vous propose un cadre de vie harmonieux et raffiné pour satisfaire vos attentes.\n\nAuthentique et pleine de charme, Larmor-Plage est une station du littoral qui favorise les activités balnéaires et touristiques. Ses plages de sable fin, son port de plaisance, son Casino et son front de mer ravissent toute la famille, les petits comme les grands, actifs et retraités.\n\nLa rue des Œillets est une rue calme, à sens unique, ponctuée de part et d’autre par les plages de Toulhars et de la Nourriguel . Depuis cet emplacement rare et privilégié, tout se fait à pied. C’est en longeant la plage de Toulhars que vous rejoindrez le centre-ville et profiterez de ses nombreux commerces et restaurants, ses marchés nocturnes et dominicaux, ses équipements communaux (église, mairie, écoles, médiathèque, transports)… ',
    'IMGONGLET3': '../Villes/larmor.jpg',
    'GALLERY': new Set(['../Programs/LJT/1.jpg', '../Programs/LJT/2.jpg', '../Programs/LJT/3.jpg', '../Programs/LJT/4.jpg'])
};

export const PreviewLJT = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesLJT['TITLE']}
            statut={ValuesLJT['STATUT']}
            localisation={ValuesLJT['LOCALISATION']}
            logo={ValuesLJT['LOGO']}
            url={ValuesLJT['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageLJT = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesLJT['TITLE']}
                        sousTitle={ValuesLJT['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesLJT['ONGLET1']}
                        text={ValuesLJT['TEXTONGLET1']}
                        img={ValuesLJT['IMGONGLET1']}
                        listImg={ValuesLJT['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesLJT['ONGLET2']}
                        text={ValuesLJT['TEXTONGLET2']}
                        img={ValuesLJT['IMGONGLET2']}
                    />
                    <ContentImgLeft
                        title={ValuesLJT['ONGLET3']}
                        text={ValuesLJT['TEXTONGLET3']}
                        img={ValuesLJT['IMGONGLET3']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesLJT['LAT']}
                        long={ValuesLJT['LONG']}
                        country={ValuesLJT['ADDRESSCOUNTRY']}
                        adress={ValuesLJT['ADDRESS']}
                        currentPage={ValuesLJT['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}